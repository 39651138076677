import React, { useState, useRef, useEffect } from 'react';
import SignatureCanvas from 'react-signature-canvas';

const clients = [
  { id: 1, nom: "Uniqlo" },
  { id: 2, nom: "Société B" },
  { id: 3, nom: "KFC Saint Aunès" },
  { id: 4, nom: "Restaurant D" },
  { id: 5, nom: "Hôtel E" },
];

const typesDechets = [
  "Cartons", "Plastiques", "Papiers", "Verre", "Emballages", "Bois A", "Polystyrènes", 
  "Biodéchets", "Ordures Ménagères", "DIB", "Box Plastiques", "Box Gobelets", "Box verre", 
  "Box 5 en 1", "Box Aluminium", "Box Papiers/cartons", "Box biodéchets"
];

const CollecteForm = () => {
  const [clientId, setClientId] = useState('');
  const [dechets, setDechets] = useState({});
  const [nonConformes, setNonConformes] = useState('non');
  const [commentaire, setCommentaire] = useState('');
  const [photos, setPhotos] = useState([]);
  const signatureRef = useRef();

  useEffect(() => {
    const initialDechets = {};
    typesDechets.forEach(type => {
      initialDechets[type] = { selected: false, nombre: 0, erreurs: '' };
    });
    setDechets(initialDechets);
  }, []);

  const handleDechetChange = (type, field, value) => {
    setDechets(prev => ({
      ...prev,
      [type]: { ...prev[type], [field]: value }
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    const formData = new FormData();
    formData.append('clientId', clientId);
    
    const dechetsSelectionnes = Object.entries(dechets)
      .filter(([_, value]) => value.selected)
      .map(([type, value]) => ({ type, nombre: value.nombre, erreurs: value.erreurs }));
    formData.append('dechets', JSON.stringify(dechetsSelectionnes));
    
    formData.append('nonConformes', nonConformes);
    formData.append('commentaire', commentaire);
    
    photos.forEach((photo) => {
      formData.append('photos', photo);
    });
    
    if (signatureRef.current) {
      const signatureDataURL = signatureRef.current.toDataURL();
      formData.append('signature', signatureDataURL);
    }

    try {
      const response = await fetch('https://ecophoenix.io/api/soumettre-rapport', {
        method: 'POST',
        body: formData,
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const result = await response.json();
      alert(result.message);
      // Réinitialiser le formulaire
      setClientId('');
      setDechets({});
      setNonConformes('non');
      setCommentaire('');
      setPhotos([]);
      signatureRef.current.clear();
    } catch (error) {
      console.error('Erreur:', error);
      alert('Erreur lors de la soumission du rapport. Veuillez réessayer. Détails : ' + error.message);
    }
  };

  return (
    <div className="bg-gradient-to-r from-green-400 to-blue-500 min-h-screen p-4">
      <div className="max-w-md mx-auto bg-white rounded-xl shadow-lg overflow-hidden md:max-w-2xl">
        <div className="p-8">
          <h1 className="text-2xl font-bold text-green-600 text-center mb-6">Écophoenix - Rapport de Collecte</h1>
          <form onSubmit={handleSubmit} className="space-y-6">
            <div className="bg-gray-100 p-4 rounded-lg shadow-inner">
              <label htmlFor="client" className="block text-sm font-medium text-gray-700 mb-1">Client</label>
              <select
                id="client"
                value={clientId}
                onChange={(e) => setClientId(e.target.value)}
                required
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-green-300 focus:ring focus:ring-green-200 focus:ring-opacity-50 transition duration-150 ease-in-out"
              >
                <option value="">Sélectionnez un client</option>
                {clients.map((client) => (
                  <option key={client.id} value={client.id}>{client.nom}</option>
                ))}
              </select>
            </div>

            <div className="bg-gray-100 p-4 rounded-lg shadow-inner">
              <h2 className="block text-sm font-medium text-gray-700 mb-2">Types de déchets collectés</h2>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                {typesDechets.map((type) => (
                  <div key={type} className="bg-white p-3 rounded-md shadow">
                    <div className="flex items-center mb-2">
                      <input
                        type="checkbox"
                        id={type}
                        checked={dechets[type]?.selected || false}
                        onChange={(e) => handleDechetChange(type, 'selected', e.target.checked)}
                        className="form-checkbox text-green-600 h-5 w-5"
                      />
                      <label htmlFor={type} className="ml-2 text-sm font-medium text-gray-700">{type}</label>
                    </div>
                    {dechets[type]?.selected && (
                      <>
                        <input
                          type="number"
                          value={dechets[type]?.nombre || 0}
                          onChange={(e) => handleDechetChange(type, 'nombre', e.target.value)}
                          placeholder="Nombre"
                          className="w-full mb-2 rounded-md border-gray-300 shadow-sm focus:border-green-300 focus:ring focus:ring-green-200 focus:ring-opacity-50"
                          min="0"
                        />
                        <input
                          type="text"
                          value={dechets[type]?.erreurs || ''}
                          onChange={(e) => handleDechetChange(type, 'erreurs', e.target.value)}
                          placeholder="Erreurs de tri"
                          className="w-full rounded-md border-gray-300 shadow-sm focus:border-green-300 focus:ring focus:ring-green-200 focus:ring-opacity-50"
                        />
                      </>
                    )}
                  </div>
                ))}
              </div>
            </div>

            <div className="bg-gray-100 p-4 rounded-lg shadow-inner">
              <h2 className="block text-sm font-medium text-gray-700 mb-2">Présence de déchets non conformes</h2>
              <div className="flex items-center space-x-4 mb-2">
                <label className="inline-flex items-center">
                  <input
                    type="radio"
                    value="non"
                    checked={nonConformes === 'non'}
                    onChange={(e) => setNonConformes(e.target.value)}
                    className="form-radio text-green-600"
                  />
                  <span className="ml-2">Non</span>
                </label>
                <label className="inline-flex items-center">
                  <input
                    type="radio"
                    value="oui"
                    checked={nonConformes === 'oui'}
                    onChange={(e) => setNonConformes(e.target.value)}
                    className="form-radio text-red-600"
                  />
                  <span className="ml-2">Oui</span>
                </label>
              </div>
              {nonConformes === 'oui' && (
                <textarea
                  value={commentaire}
                  onChange={(e) => setCommentaire(e.target.value)}
                  placeholder="Commentaire sur les déchets non conformes"
                  className="w-full rounded-md border-gray-300 shadow-sm focus:border-red-300 focus:ring focus:ring-red-200 focus:ring-opacity-50"
                  rows="3"
                />
              )}
            </div>

            <div className="bg-gray-100 p-4 rounded-lg shadow-inner">
              <label className="block text-sm font-medium text-gray-700 mb-2">Photos</label>
              <input
                type="file"
                onChange={(e) => setPhotos(Array.from(e.target.files))}
                multiple
                accept="image/*"
                className="mt-1 block w-full text-sm text-gray-500
                  file:mr-4 file:py-2 file:px-4
                  file:rounded-full file:border-0
                  file:text-sm file:font-semibold
                  file:bg-green-50 file:text-green-700
                  hover:file:bg-green-100"
              />
            </div>

            <div className="bg-gray-100 p-4 rounded-lg shadow-inner">
              <label className="block text-sm font-medium text-gray-700 mb-2">Signature de l'agent</label>
              <SignatureCanvas
                ref={signatureRef}
                canvasProps={{
                  className: 'border rounded-md w-full h-40 bg-white'
                }}
              />
              <button
                type="button"
                onClick={() => signatureRef.current.clear()}
                className="mt-2 px-4 py-2 bg-gray-200 text-gray-800 rounded-md hover:bg-gray-300 transition duration-150 ease-in-out"
              >
                Effacer la signature
              </button>
            </div>

            <button
              type="submit"
              className="w-full flex justify-center py-3 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 transition duration-150 ease-in-out"
            >
              Soumettre le rapport
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default CollecteForm;