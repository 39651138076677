import React from 'react';
import CollecteForm from './CollecteForm';

function App() {
  return (
    <div className="App">
      <CollecteForm />
    </div>
  );
}

export default App;